#
# Quickportal Modal box
#

$.extend $.qs, {

  Modal: class
    constructor: (type, options) ->
      @id    = "modal_#{Date.now()}"
      @_node = switch type
        when 'alert'   then @_alert options['title'], options['message'], options['callback']
        when 'confirm' then @_confirm options['title'], options['message'], options['callback']
        when 'content'
          $(document).on "ajax:success.#{@id}", "##{@id}", (e) =>
            [data, status, xhr] = e.detail
            try
              if $(xhr.responseText).findIncludeSelf('[data-modal="body"]').length
                @_removeStyles().addClass($(e.target).data('modal-style')) if $(e.target).data('modal-style')
                elmt = @_node.find '[data-modal="content"]'
                elmt.html(xhr.responseText).trigger 'qs:domChanged'
                $.qs.actionCallbackXhr xhr
                @_resetPositionCache()
                @_position()
              else
                @destroy()
          @_create options['content'], options['style']

      # TODO : Modal, Resize and Touch Event
      $(window).on "resize.#{@id}", => @_position()

      $(document).on "page:before-unload.#{@id}", => @destroy()
      @_node.on "qs:close.#{@id}", => @destroy()
      @_node.on 'click', '[data-modal="close"]', (e) =>
        e.preventDefault()
        @destroy()
      @_position()

    show: -> @_node.addClass 'modal-show'

    destroy: ->
      $([window, document]).off ".#{@id}"
      @_node.remove()

    # Static

    @init: ->
      # Remote modals
      $(document).on 'ajax:success', '[data-modal="true"]',  (e) =>
        [data, status, xhr] = e.detail
        mt      = $(e.target).data 'modal-style'
        options = {content: xhr.responseText}
        options = $.extend(options, {style: mt}) if mt
        (new @ 'content', options).show()
        $.qs.actionCallbackXhr xhr

      # Override Rails confirms
      $(document).on 'confirm', (e) ->
        element = $(e.target)
        return true unless element.attr('data-confirm')
        $.qs.confirm element.data('title'), element.data('confirm'), ->
          element.removeAttr('data-confirm')
          element.get(0).click()
        false

    # Private

    _create: (content, style='') ->
      $ """
        <div id="#{@id}" class="modal #{style}">
          <div class="modal-dialog">
            <div class="modal-dialog-inner">
              <div class="modal-header"><button class="modal-close" data-modal="close"><i class="fa fa-times"></i></button></div>
              <div data-modal="content">#{content}</div>
            </div>
          </div>
          <div class="modal-overlay" data-modal="close"></div>
        </div>
        """
        .appendTo('body').trigger 'qs:domChanged'

    _content: (body, footer) ->
      """
      <div class="modal-body" data-modal="body">
        <div class="modal-scrollbox">
          #{body}
        </div>
      </div>
      <div class="modal-footer" data-modal="footer">
        #{footer}
      </div>
      """

    _alert: (title, message, callback) ->
      body   = "<h3>#{title}</h3><p>#{message}</p>"
      footer = """
               <a href="#" class="button fixed-width" data-modal="close">#{I18n.t 'common.ok'}</a>
               """
      node = @_create @_content(body, footer)
      node.find('[data-modal="close"]').one 'click', (e) => callback() if callback
      node

    _confirm: (title, message, callback) ->
      body   = "<h3>#{title}</h3><p>#{message}</p>"
      footer = """
               <a href="#" class="button fixed-width" data-modal="confirm">#{I18n.t 'common.yes'}</a>
               <a href="#" class="button border fixed-width" data-modal="close">#{I18n.t 'common.no'}</a>
               """
      node = @_create @_content(body, footer)
      node.find('[data-modal="confirm"]').one 'click', (e) =>
        e.preventDefault()
        @destroy()
        callback()
      node

    _position: ->
      pad   = 20
      @_m   ||= @_node.children(':first')
      @_ms  ||= @_m.find('.modal-scrollbox')
      @_mfh ||= @_m.outerHeight() - @_ms.outerHeight()
      wh    = $(window).outerHeight()

      if wh - pad * 2 < @_mfh + @_ms.prop('scrollHeight')
        @_m.css {top: pad, 'margin-top': 0}
        @_ms.outerHeight(wh - @_mfh - pad * 2, true)
        @_node.addClass 'with-scroll'
      else
        @_node.removeClass 'with-scroll'
        @_m.css {top:'50%', 'margin-top': -@_m.outerHeight()/2}

      @_m.css {left: -@_m.outerWidth()/2}

      @_node

    _resetPositionCache: ->
      @_ms = @_mfh = null

    _removeStyles: ->
      @_node.removeClass 'small normal large nopad'
      @_node


  # Helpers

  alert: (title, message, onCloseCallback) ->
    (new @Modal 'alert', {title: title, message: message, callback: onCloseCallback}).show()

  confirm: (title, message, onConfirmCallback) ->
    (new @Modal 'confirm', {title: title, message: message, callback: onConfirmCallback}).show()

}

$ -> $.qs.init 'Modal'
