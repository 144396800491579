#
# Quickportal JS commons
#

import Rails from '@rails/ujs'
import Dropzone from 'dropzone'
import Slideout from 'slideout'
require 'chosen-js'

# Force a dom render to have proper transitions
$.fn.extend forceDomRendering: ->
  @get(0).clientHeight
  @

# JQuery findIncludeSelf
$.fn.extend findIncludeSelf: (selector) -> @find(selector).addBack(selector)

$.qs ||= {}

$.extend $.qs, {

  # Init objects
  init: (objects...) ->
    @[object].init() for object in objects

  # Execute a function by name
  executeByName: (name, context=@, args...) ->
    namespaces = name.split '.'
    fn         = namespaces.pop()

    try
      context = context[namespace] for namespace in namespaces
      throw(false) unless $.isFunction context[fn]
    catch e
      return false

    context[fn].apply context, args

  # Execute the action callback
  actionCallback: (controller, action) ->
    $.qs.executeByName "controllers.#{controller}.#{action}_act" if controller && action

  # Execute the action callback from xhr object
  actionCallbackXhr: (xhr) -> @actionCallback xhr.getResponseHeader('X-Controller'), xhr.getResponseHeader('X-Action')

  # Generic queue class
  Queue: class
    constructor: (@_data = []) ->
    isEmpty:       => @_data.length == 0
    enqueue: (obj) => @_data.push obj
    shift:         => @_data.shift()
    clear:         => @_data = []
    size:          => @_data.length

  # Flash message
  flashMessage:
    init: ->
      @reset()
      $(document).on 'ajax:success', (e) =>
        xhr  = e.detail[2]
        data = xhr.responseText
        unless data && typeof data is 'string' && data.match(/^Turbolinks/)
          @_createFromXhr(xhr)
      $(document).on 'ajax:error', (e) => @_createFromXhr(e.detail[2])

    reset: ->
      @_queue      = new $.qs.Queue
      @_displaying = false
      @

    create: (message, type='notice') ->
      if @_displaying
        @_queue.enqueue arguments
      else
        @_node().toggleClass 'alert', type is 'alert'
        @_message().text message
        @_show()

    createFromDom: ->
      @_show() if !@_displaying && @_message().text()

    _createFromXhr: (xhr) ->
      if xhr.getResponseHeader('X-Message')
        @create decodeURIComponent(escape(xhr.getResponseHeader('X-Message'))), xhr.getResponseHeader('X-Message-Type')

    _node: -> $ '[data-flash-message]'

    _message: -> @_node().find '.message'

    _show: ->
      @_displaying = true
      @_node().forceDomRendering().addClass('show')
      setTimeout =>
        @_node().removeClass 'show'
        @_node().one 'transitionend', => @_onHide()
      , 2500

    _onHide: ->
      @_node().removeClass 'alert'
      @_message().text ''
      @_displaying = false
      @create.apply @, @_queue.shift() unless @_queue.isEmpty()

  # Custom select
  customSelect:
    init: ->
      $(document).on 'qs:domChanged', (e) => @_load(e.target)

    _selectors:
      '.chosen-select'           : { disable_search_threshold: 10, width: '100%' }
      '.chosen-select-deselect'  : { allow_single_deselect: true, width: '100%' }
      '.chosen-select-no-search' : { disable_search_threshold: 100, width: '100%' }

    _load: (target) ->
      $(target).find(selector).chosen @_selectors[selector] for selector of @_selectors

  # Panel Dropdown
  panelDropdown:
    init: ->
      $(document).on 'qs:domChanged', (e) => @_load(e.target)

    _selector: '.panel-dropdown'

    _load: (target) ->
      $(target).find("#{@_selector} a").click (e) =>
        e.preventDefault()
        p = $(e.currentTarget).parent()

        if p.is '.active'
          @_closeAll()
        else
          @_closeAll()
          p.addClass 'active'
          $('.fs-inner-container.content').addClass 'faded-out'
          $('#wrapper').on 'click.panel', (e) => @_closeAll() if $(e.target).find(@_selector).length > 0

      $(target).find("#{@_selector} button[type=\"button\"]").click (e) => @_closeAll()

    _closeAll: ->
      $('.panel-dropdown').removeClass 'active'
      $('.fs-inner-container.content').removeClass 'faded-out'
      $('#wrapper').off '.panel'

  # Extend UJS : update DOM on ajax:success with the following actions : update, prepend, append, replace, remove
  # A selector should be provided in the data attribute
  # A replace action without selector will replace the whole template
  ujsExtended:
    init: ->
      $(document).on 'ajax:success', '[data-update],[data-prepend],[data-append],[data-replace],[data-remove]', (e) ->
        [data, status, xhr] = e.detail
        unless xhr.responseText and typeof xhr.responseText is 'string' and xhr.responseText.match(/^Turbolinks/)
          if elmt = $(e.target).data 'remove'
            $(elmt).trigger('qs:domRemove').remove()
          else
            if (elmt = $(e.target).data 'update')?
              if elmt
                $(elmt).html xhr.responseText
              else
                elmt = '#main-content'
                $(elmt).html xhr.responseText
                window.scrollTo 0,0
                $.qs.actionCallbackXhr xhr
            else if elmt = $(e.target).data 'prepend' then $(elmt).prepend xhr.responseText
            else if elmt = $(e.target).data 'append'  then $(elmt).append xhr.responseText
            else if elmt = $(e.target).data 'replace' then elmt = $(xhr.responseText).replaceAll(elmt)

            if elmt
              $(elmt).get(0).scrollIntoView({ behavior: 'smooth', block: 'start' }) if $(e.target).is('[data-scroll-to-elmt]')
              $(elmt).trigger 'qs:domChanged' if elmt

  # Ajax file upload using FormData
  multipart:
    init: ->
      $(document).on 'submit', 'form[data-multipart]', (e) ->
        f = $(e.target)
        $.ajax({
          url: f.attr('action'), data: new FormData(f.get(0)), cache: false, contentType : false, processData : false, type: 'POST',
          beforeSend: ->
            $(document).on 'click.lock', (e) ->
              $.qs.flashMessage.create I18n.t('js.upload.lock'), 'alert'
              e.preventDefault()
            if Rails.fire(f[0], 'ajax:beforeSend')
              f.find('button').prop 'disabled', true
            else
              false
          ,
          complete: (data, status, xhr) ->
            $(document).off 'click.lock'
            f.find('button').prop 'disabled', false
            f.trigger jQuery.Event('ajax:complete', { detail: [data, status, xhr] })
          ,
          success: (data, status, xhr) ->
            f.trigger jQuery.Event('ajax:success', { detail: [data, status, xhr] })
          ,
          xhr: ->
            xhr = $.ajaxSettings.xhr()
            xhr.upload.addEventListener 'progress', (e) ->
              f.trigger 'qs:upload:progress', [ e.loaded, e.total ]
            , false
            xhr
        })
        false

  # Mobile slideout
  mobile_slideout:
    init: ->
      $(document).on 'turbolinks:load', (e) => @_create() if $(window).outerWidth() <= 1024
      $(window).on 'resize', (e) => @_resize()
    object: null

    _selectors:
      panel: '#wrapper'
      menu: '#menu-mobile'
      trigger: '#header div[data-menu-trigger]'

    _create: ->
      $('html').removeClass 'slideout-open'
      $(@_selectors.trigger).click => @object.toggle()
      @object = new Slideout(
        panel: $(@_selectors.panel).get(0),
        menu:  $(@_selectors.menu).get(0),
        side: 'right', padding: 256, tolerance: 70
      ).on('beforeopen', =>
        $(@_selectors.trigger).addClass('open')
      ).on('beforeclose', =>
        $(@_selectors.trigger).removeClass('open')
      )

    _resize: ->
      if $(window).outerWidth() <= 1024 and @object is null
        @_create()
      else if $(window).outerWidth() > 1024 and @object isnt null
        @object.close().destroy()
        $(@_selectors.trigger).off()
        @object = null

  # Email link
  emailLink:
    init: ->
      $(document).on 'qs:domChanged', (e) => @_load(e.target)

    _selector: '.email-link'

    _load: (target) ->
      $(target).find(@_selector).click (e) =>
        e.preventDefault()
        d = e.target.dataset
        tmp = $('<input style="opacity:0;">').appendTo('body').val("#{d.name}@#{d.domain}.#{d.tld}").select()
        document.execCommand 'copy'
        tmp.remove()
        $.qs.flashMessage.create I18n.t('js.email_clipboard')

  # Emoji
  emoji:
    library:
      '😊': ":[\\\)D]{1}"
      '😉': ";[\\\)D]{1}"
      '😂': ":'[\\\)D]{1}"
      '😝': ":p"
      '😍': "\\\<3"
      '😲': ":o"
      '😖': ":\\\|"
      '😞': ":\\\("
      '😢': ":'\\\("
      '😸': "\\\^{2}"

    init: ->
      @library[k] = new RegExp "(^|\\s)(#{e})($|\\s)", 'gi' for k, e of @library
      $(document).on 'keyup', 'textarea[data-emoticonize]', (e) =>
        @_emoticonize $(e.currentTarget) if e.which is 32 # or e.which is 229 (Samsung predictive kb issue)

    emoticonize: (node) ->
      text      = node.val()
      curPos    = node.get(0).selectionStart
      curLength = text.length
      #$('.chat-history-search-counter').attr 'data-value', "##{text.slice(-1)}#"
      text      = text.replace e, "$1#{k}$3" for k, e of @library
      node.val text
      newPos    = curPos + text.length - curLength
      node.get(0).setSelectionRange(newPos, newPos)

}

$.qs.init 'flashMessage', 'customSelect', 'panelDropdown', 'ujsExtended', 'multipart', 'mobile_slideout', 'emailLink', 'emoji'

$(document).on 'submit', 'form[method=get]', (e) ->
  e.preventDefault()
  Turbolinks.visit(@action + (if @action.indexOf('?') == -1 then '?' else '&') + $(@).serialize())

# Back to top
$(window).scroll -> $('#backtotop').toggleClass 'visible', $(window).scrollTop() >= $(window).height()
$(document).on 'click', '#backtotop a', (e) ->
  e.preventDefault()
  $('html, body').animate { scrollTop: 0 } , 500

# Remove cookie disclaimer
$(document).on 'click', '#cookie-disclaimer strong a', (e) ->
  e.preventDefault()
  expires = new Date()
  expires.setTime expires.getTime() + (365 * 86400 * 1000)
  document.cookie = "cookie-disclaimer=1;expires=#{expires.toUTCString()};path=/";
  $('#cookie-disclaimer').remove()

# Google AdSense
if window.adsbygoogle?
  window.adsbygoogle.push google_ad_client: 'ca-pub-6243905826940952', enable_page_level_ads: true

$(document).on 'turbolinks:load', ->
  I18n.locale = document.documentElement.lang

  $.qs.flashMessage.reset().createFromDom()

  $.qs.actionCallback $('body').data('controller'), $('body').data('action')

  $('.tip,.tooltip').tooltipster functionInit: (instance, helper) ->
    options = $(helper.origin).attr('data-tooltip-options')
    if options
      options = JSON.parse(options)
      $.each(options, (name, option) -> instance.option(name, option))

  # Menus (submenus)
  $('.side-menu ul li a').click (e) ->
    if $(e.target).closest('li').has('ul').length
      $(e.target).parent('li').toggleClass 'active'

  # Google Analytics
  if window.ga?
    ga 'set',  'location', location.href.split('#')[0]
    ga 'send', 'pageview', { title: document.title }

  # Trigger a dom change for custom components
  $(document).trigger 'qs:domChanged'
