#
# Controllers / actions specific javascript
#

import Dropzone from 'dropzone'
import Sortable from 'sortablejs'
import EasyMDE from 'easymde'

$.extend true, $.qs, {

  controllers:

    admin:

      studios:

        new_act: ->
          $('#studio_logo').change (e) -> $(e.target).prev().html($(e.target).data('selected'))

          $('form[data-multipart]').on 'qs:upload:progress', (e, loaded, total) ->
            $(e.currentTarget).find('button').html I18n.t('js.upload.sending', { percent: Math.round(loaded / total * 100) })

          $('.switch input').change (e) -> $(e.target).parents('.add-listing-section').toggleClass('switcher-on', e.target.checked is true)

        create_act: -> $.qs.controllers.admin.studios.new_act()

        edit_act: ->
          $.qs.controllers.admin.studios.create_act()

          $('[data-dropzone]').each ->
            node = $(@)
            dz = new Dropzone(node[0], {
              url: node.data('url'),
              timeout: 5 * 60 * 1000,
              headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
              dictDefaultMessage: node.children().detach().html()
            })
            dz.on 'success', (file, data) ->
              setTimeout ->
                dz.removeFile(file)
                $('#gallery').append(data)
              , 1500

          Sortable.create $('#gallery')[0], onUpdate: (e) ->
            $.ajax $('#gallery').data('url'), { method: 'PUT', data: { photo_id: $(e.item).data('photo'), position: e.newIndex + 1 }}

          for desc in ['studio_description_fr', 'studio_description_en']
            new EasyMDE {
              element: document.getElementById(desc)
              toolbar: ['bold', 'italic', '|', 'quote', 'unordered-list', 'ordered-list',
                        'horizontal-rule', 'link', '|', 'clean-block', 'preview'],
              maxHeight: '200px',
              marginBottom: '20px'
              status: false
            }

        update_act: -> $.qs.controllers.admin.studios.edit_act()

      blog_posts:

        new_act: -> $.qs.controllers.admin.blog_posts.edit_act()
        create_act: -> $.qs.controllers.admin.blog_posts.edit_act()
        update_act: -> $.qs.controllers.admin.blog_posts.edit_act()

        edit_act: ->
          $('#blog_post_image').change (e) -> $(e.target).prev().html($(e.target).data('selected'))

          $('form[data-multipart]').on 'qs:upload:progress', (e, loaded, total) ->
            $(e.currentTarget).find('button').html I18n.t('js.upload.sending', { percent: Math.round(loaded / total * 100) })

          new EasyMDE {
            element: document.getElementById('blog_post_content')
            toolbar: ['bold', 'italic', 'heading-smaller', 'heading-bigger', '|', 'quote', 'unordered-list', 'ordered-list', 'horizontal-rule',
                      '|', 'link', 'image', '|', 'clean-block', 'preview'],

          }

    admin_company:

      companies:

        kyc_act: ->
          $('input[type="file"]').change (e) -> $(e.target).parents('form').submit()

          $('form[data-multipart]').on 'qs:upload:progress', (e, loaded, total) ->
            $(e.currentTarget).find('.upload-button').html I18n.t('js.upload.sending', { percent: Math.round(loaded / total * 100) })

      studios:

        edit_act: -> $.qs.controllers.admin.studios.edit_act()
        update_act: -> $.qs.controllers.admin.studios.edit_act()

}, true