/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Lib's CSS
import 'flatpickr/dist/flatpickr.min.css'
import 'tooltipster/dist/css/tooltipster.bundle.min.css'
import 'easymde/dist/easymde.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'plyr/dist/plyr.css'

$.qs ||= {};

require("@rails/ujs").start();
require("turbolinks").start();

import I18n from 'i18n-js';
global.I18n = I18n;

require("config");
require("main");
require("modal");
require("controllers");
require("admin_controllers");
